/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

//gatsby-browser.js
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import { AppProvider } from '@src/context';

import '@styles/global.css';
import '@styles/fonts.css';
import '@styles/screenshots.css';

const onRedirectCallback = (appState) => {
    // Use Gatsby's navigate method to replace the url
    const redirectUrl = !['/', '/verify-your-email', '/register'].includes(appState?.returnTo) ? appState?.returnTo : '/member/dashboard';
    navigate(redirectUrl, { replace: true });
};

export const wrapRootElement = ({ element }) => {
    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN}
            clientId={process.env.GATSBY_AUTH0_CLIENTID}
            authorizationParams={{
                // redirect_uri must be added in Auth0 under Allowed Callback URLs
                redirect_uri: `${window.location.origin}/member/dashboard`,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <AppProvider>{element}</AppProvider>
        </Auth0Provider>
    );
};
