import axios from 'axios';

export const isEDMode =
    typeof window !== 'undefined' ? window.location.href.indexOf('ed=1') > 0 : false;
export const mobileMedia = '(min-width: 0px) and (max-width: 1200px)';
export const tabletMedia = '(min-width: 768px) and (max-width: 1200px)';

export const isMobile = (media = false) => {
    if (typeof window !== 'undefined') {
        return window.matchMedia(media || mobileMedia).matches;
    }

    return true;
};

export const isTablet = () => {
    if (typeof window !== 'undefined') {
        return window.matchMedia(tabletMedia).matches;
    }

    return true;
};

export const bounds = (elem) => {
    const rect = elem.getBoundingClientRect();

    return {
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
        width: rect.width,
        height: rect.height,
    };
};

export const getWinSize = () => {
    if (typeof window !== 'undefined') {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const x = w.innerWidth || e.clientWidth || g.clientWidth;
        const y = w.innerHeight || e.clientHeight || g.clientHeight;

        return { width: x, height: y };
    }

    return { width: 0, height: 0 };
};

export const inViewport = (elem, entireBox = false, xOffset = 0, yOffset = 0) => {
    if (typeof window !== 'undefined') {
        const bds = bounds(elem);
        const doc = getWinSize();

        if (!entireBox) {
            return bds.top >= 0 && bds.left >= 0 && bds.top <= doc.height && bds.left <= doc.width;
        } else {
            const topLimit = bds.top - doc.height;
            const bottomLimit = bds.top + bds.height;
            const leftLimit = bds.left - doc.width;
            const rightLimit = bds.left + bds.width;

            if (topLimit <= 0 && bottomLimit >= 0 && leftLimit <= 0 && rightLimit >= 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    return false;
};

export const debounce = (func, wait, immediate) => {
    let timeout = null;

    return function () {
        const context = this;
        const args = arguments;

        const later = () => {
            timeout = null;

            if (!immediate) {
                func.apply(context, args);
            }
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
};

export const getOS = () => {
    let OSName = 'unknown-os';

    if (navigator.appVersion.includes('Win')) OSName = 'win';
    if (navigator.appVersion.includes('Mac')) OSName = 'mac';
    if (navigator.appVersion.includes('X11')) OSName = 'unix';
    if (navigator.appVersion.includes('iPhone')) OSName = 'ios';
    if (navigator.appVersion.includes('iPad')) OSName = 'ios';
    if (navigator.appVersion.includes('Linux')) OSName = 'linux';

    return OSName;
};

export const offset = (elem) => {
    if (typeof window !== 'undefined') {
        const rect = bounds(elem);
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    return { top: 0, left: 0 };
};

export const isExactPath = (path) => {
    if (typeof window !== 'undefined') {
        return window.location.pathname === path;
    }
};

export const focusLoop = (e, selector) => {
    const tabPressed = e.key === 'Tab' || e.keyCode === 9;

    if (!tabPressed) return;

    const elems = document.querySelectorAll(selector);

    if (elems?.length) {
        const firstElem = elems[0];
        const lastElem = elems[elems.length - 1];

        if (!Array.from(elems).includes(document.activeElement)) {
            firstElem.focus();
        }

        if (e.shiftKey) {
            if (document.activeElement === firstElem) {
                lastElem.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastElem) {
                firstElem.focus();
                e.preventDefault();
            }
        }
    }
};

export const getCookieByName = (cookieName) => {
    const cookieValue = document.cookie.split('; ').find((row) => row.startsWith(cookieName + '='));
    if (cookieValue === undefined) {
        return null;
    }
    return cookieValue.split('=')[1];
};

export const getGatsbyImageData = (data, fileName) => {
    return data.allFile.edges.find((file) => file.node.relativePath.includes(fileName))?.node
        .childImageSharp.gatsbyImageData;
};

export const setSessionStorage = (key, value) => {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, value);
    }
};
export const getSessionStorage = (key) => {
    if (typeof window !== 'undefined') {
        return sessionStorage.getItem(key);
    }
    return null;
};
export const removeSessionStorage = (key) => {
    if (typeof window !== 'undefined') {
        sessionStorage.removeItem(key);
    }
};
export const setUserInfoSession = (userData) => {
    setSessionStorage('isLoggedIn', 1);
    setSessionStorage('profile_img', userData.picture); // user profile image
    setSessionStorage('u_treatment_type', userData.orenitram_treatment_type); // user orenitram treatment type
    setSessionStorage('u_start_date', userData.orenitram_start_date); // user start date
    setSessionStorage('u_crm_cst', userData.orenitram_crm_optin); // user CRM consent
};
export const removeUserInfoSession = () => {
    const sessionKeys = ['isLoggedIn', 'profile_img', 'u_treatment_type', 'u_start_date', 'u_crm_cst'];
    sessionKeys.forEach((key) => {
        removeSessionStorage(key);
    });
};


export const fetchAPIwToken = () => {
    const getHeaders = (authToken) => {
        const headers = { 'Content-Type': 'application/json' };
        if (authToken) {
            headers['x-api-key'] = authToken;
        }
        return headers;
    }

    const request = {
        get: async (url, authToken, data) => {
            const response = await axios.get(url, {
                headers: getHeaders(authToken),
                params: data,
            });
            return response;
        },
        post: async (url, authToken, data) => {
            const response = await axios.post(
                url,
                data,
                {
                    headers: getHeaders(authToken),
                }
            );
            return response;
        },
    };

    return request;
}
