import React, { useState, createContext } from 'react';
import { getSessionStorage } from '@src/utils/helper.js';

const defaultState = {
    exitModalOpen: false,
    showExitModal: () => { },
    externalUrl: null,
    setExternalUrl: () => { },
    modalTrigger: null,
    setModalTrigger: () => { },
};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
    const [userData, setUser] = useState(null);
    const [isTextZoom, setTextZoom] = useState(false);
    const [isUserLoaded, setUserLoaded] = useState(false);
    const [loginStatus, setLoginStatus] = useState(null);
    const [exitModalOpen, showExitModal] = useState(defaultState.modalTrigger);
    const [externalUrl, setExternalUrl] = useState(defaultState.externalUrl);
    const [modalTrigger, setModalTrigger] = useState(defaultState.modalTrigger);

    if (userData === null) {
        setUser({
            ...(getSessionStorage('profile_img') ? { picture: getSessionStorage('profile_img') } : null),
            ...(getSessionStorage('u_crm_cst')
                ? { orenitram_crm_optin: getSessionStorage('u_crm_cst') === 'true' }
                : null),
            ...(getSessionStorage('u_start_date')
                ? { orenitram_start_date: getSessionStorage('u_start_date') }
                : null),
        });
    }

    // transition | titration_kit | alt_dosing
    const getUserTreatmentType = userData?.orenitram_treatment_type || 'transition';
    const getUserStartDate = userData?.orenitram_start_date || '0000-00-01';
    const getUserCrmConsent = userData?.orenitram_crm_optin || '';
    let isNewPatient = true;

    if (userData !== null && userData?.orenitram_start_date) {
        const currentDate = new Date();
        const userStartDate = new Date(userData?.orenitram_start_date);
        const monthDiff = currentDate.getMonth() - userStartDate.getMonth();
        const yearDiff = currentDate.getFullYear() - userStartDate.getFullYear();
        const duration = monthDiff + yearDiff * 12;
        isNewPatient = duration < 6;
    }

    const store = {
        isUserLoaded,
        setUserLoaded,
        exitModalOpen,
        showExitModal,
        externalUrl,
        setExternalUrl,
        modalTrigger,
        setModalTrigger,
        userData,
        setUser,
        loginStatus,
        setLoginStatus,
        getUserTreatmentType,
        getUserStartDate,
        getUserCrmConsent,
        isNewPatient,
        isTextZoom,
        setTextZoom,
    };
    return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
